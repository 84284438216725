<template>
	<div class="caption d-flex align-items-center mb-3">
		<slot name="icon">
			<IconSax
				v-if="icon"
				:name="icon"
				class="mr-3 d-flex align-items-center justify-content-center border border-primary border-1 rounded-circle"
				primary
				size="1rem"
			/>
		</slot>
		<div>
			<slot name="title">
				<TextPlay
					class="font-color"
					weight="semibold"
					v-html="title"
				/>
			</slot>
			<slot name="desc">
				<TextPlay
					size="sm"
					:text="description"
				/>
			</slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		icon: {
			type: String,
			default: "",
		},
		title: {
			type: String,
			default: "",
		},
		description: {
			type: String,
			default: "",
		},
	},
};
</script>

<style lang="scss">
.caption {
	p {
		color: var(--text-color);
	}

	.isax {
		padding: 0.7rem;
		border-color: var(--maincolor) !important;
	}

	.font-color {
		color: var(--fontcolor);
	}
}
</style>
