<template>
	<div>
		<TextPlay
			weight="semibold"
			color="var(--fontcolor)"
			:text="$t('gamification.recognition_medals')"
		/>
		<TextPlay
			color="var(--text-color)"
			class="mb-5"
			v-html="medalsQuantity > 0 ? $t('gamification.about_recognition') : $t('gamification.no_medals_configureted')"
		/>
		<Achievements
			v-if="medalsQuantity > 0"
			:title="$t('gamification.my_achievements')"
			:description="medalsList.length < 1 ? $t('gamification.no_student_medals') : ''"
			:medalsList="medalsList"
			showDefaultList
			:limit="medalsQuantity"
		/>
	</div>
</template>

<script>
import Achievements from "@/components/Achievements.vue";
import { mapActions, mapGetters } from "vuex";

export default {
	components: {
		Achievements,
	},
	computed: {
		...mapGetters({
			medalsList: "gamification/getMembersMedals",
			getMe: "auth/getMe",
			medalsQuantity: "gamification/getMedalsConfiguredQuantity",
		}),
	},
	methods: {
		...mapActions({
			fetchMembersMedals: "gamification/fetchMembersMedals",
		}),
	},
	watch: {
		"getMe.member.id": {
			async handler(newId) {
				await this.fetchMembersMedals(newId);
			},
			immediate: true,
		},
	},
};
</script>
