<template>
	<div class="gamification d-flex flex-column flex-md-row">
		<div class="gamification__about-section p-0 col-12 col-md-6">
			<TitlePage
				class="mb-2"
				:title="$t('gamification.title')"
			>
				<IconSax name="cup" />
			</TitlePage>

			<div class="gamification__about-section_info mb-4">
				<TextPlay
					color="var(--text-color)"
					v-html="$t('gamification.about', { link: 'https://faq.voompcreators.com.br/' })"
				/>
			</div>

			<div class="gamification__description mb-4">
				<Caption
					icon="ranking-1"
					:title="$t('gamification.ranking_caption_title')"
					:description="$t('gamification.ranking_caption_desc')"
				/>
				<Caption
					icon="coin-1"
					:title="$t('gamification.medal_caption_title')"
					:description="$t('gamification.medal_caption_desc')"
				/>
				<Caption
					icon="buy-crypto"
					:title="$t('gamification.coin_caption_title')"
					:description="$t('gamification.coin_caption_desc')"
				/>
				<Caption
					icon="discount-shape"
					:description="$t('gamification.coupon_caption_desc')"
					:title="$t('gamification.coupon_caption_title')"
				/>
			</div>

			<ButtonPlay
				type="normal"
				@click="handleActivateGamification"
				:text="$t('gamification.go_to_config_btn')"
				icon="cup"
			/>

			<FeedbackModalPlay
				:isLoading="saveLoading"
				id="activeGamificationModal"
				:content="$t('gamification.reactivate_gamification_desc')"
				:title="$t('gamification.configurate_gamification')"
			>
				<template slot="actions">
					<div class="d-flex flex-column align-items-center w-100 mt-2">
						<ButtonPlay
							class="w-100 mb-2"
							@click="reactivateGamification"
							:text="$t('gamification.reactivate_gamification')"
							icon="cup"
							iconType="bold"
							type="normal"
						/>
						<ButtonPlay
							class="w-100 mb-2"
							@click="configNewGamification"
							:text="$t('gamification.config_new_gamification')"
							icon="cup"
							iconType="bold"
							type="normal"
						/>
					</div>
				</template>
			</FeedbackModalPlay>
		</div>
		<div class="gamification__example-image d-none d-md-flex justify-content-center col-6">
			<img :src="gamificationImgSrc" />
		</div>
	</div>
</template>

<script>
import TitlePage from "@/components/common/TitlePage.vue";
import Caption from "@/components/Caption.vue";
import { mapActions, mapGetters } from "vuex";

export default {
	components: {
		TitlePage,
		Caption,
	},
	data() {
		return {
			saveLoading: false,
			assetsURL: process.env.VUE_ASSETS_URL,
		};
	},
	computed: {
		...mapGetters({
			getTheme: "config/getTheme",
			getGamificationStatus: "config/getGamificationStatus",
		}),
		gamificationImgSrc() {
			return this.getTheme == "dark"
				? `${this.assetsURL}/images/gamification/gamification-dark.png`
				: `${this.assetsURL}/images/gamification/gamification-light.png`;
		},
	},
	methods: {
		...mapActions({
			actionSaveSettings: "config/actionSaveSettings",
			deleteGamification: "gamification/deleteGamification",
		}),
		async reactivateGamification() {
			this.saveLoading = true;
			await this.actionSaveSettings({
				id: "gamefication",
				value: "on",
			});
			this.saveLoading = false;
			this.$bvModal.hide("activeGamificationModal");
			this.$router.push({ name: "gamification-config" });
		},
		async configNewGamification() {
			this.saveLoading = true;
			await this.deleteGamification();
			this.saveLoading = false;
			this.$bvModal.hide("activeGamificationModal");
			this.$router.push({ name: "gamification-config" });
		},
		handleActivateGamification() {
			if (this.getGamificationStatus === null) {
				this.$router.push({ name: "gamification-config" });
				return;
			}

			this.$bvModal.show("activeGamificationModal");
		},
	},
};
</script>

<style lang="scss">
.gamification {
	.warning-text {
		color: var(--warning-color);
	}

	&__about-section {
		&_info {
			a {
				color: var(--maincolor);
			}
		}
	}
}
</style>

<style lang="scss" scoped>
.gamification {
	padding: 64px 40px;

	&__description {
		.isax {
			padding: 0.7rem;
			border-color: var(--maincolor) !important;
		}
	}

	&__example-image img {
		width: 400px;
		object-fit: contain;
	}
}

@media (max-width: 768px) {
	.gamification {
		padding: 32px 16px;
	}
}
</style>
