<template>
	<Layout>
		<component :is="dinamycComponent()" />
	</Layout>
</template>

<script>
import AboutGamificationTemplate from "@/components/AboutGamificationTemplate.vue";
import GamificationActivatedTemplate from "@/components/GamificationActivatedTemplate.vue";
import StudentGamificationTemplate from "@/components/StudentGamificationTemplate.vue";
import { mapGetters } from "vuex";

export default {
	components: {
		AboutGamificationTemplate,
		GamificationActivatedTemplate,
	},
	computed: {
		...mapGetters({ getIsGamificationActive: "config/getIsGamificationActive", getIsAdmin: "config/getIsAdmin" }),
	},
	methods: {
		dinamycComponent() {
			if (this.getIsAdmin) {
				return this.getIsGamificationActive ? GamificationActivatedTemplate : AboutGamificationTemplate;
			}
			if (!this.getIsAdmin && this.getIsGamificationActive) {
				return StudentGamificationTemplate;
			}

			return this.$router.push({ name: "Home" });
		},
	},
};
</script>
