<template>
	<div class="gamification">
		<TitlePage
			class="mb-2"
			:title="$t('gamification.title')"
		>
			<IconSax name="cup" />
		</TitlePage>
		<div class="p-0 col-12 col-md-7">
			<TextPlay
				class="mb-5"
				color="var(--text-color)"
				:text="$t('gamification.activated_gamification')"
			/>
			<TextPlay
				color="var(--text-color)"
				class="mb-4"
			>
				{{ $t("gamification.know_more") }}
				<a
					class="info"
					target="_blank"
					href="https://faq.voompcreators.com.br/"
				>
					{{ $t("gamification.help_center") }}.
				</a>
			</TextPlay>
			<ButtonPlay
				link="/gamification/ranking"
				class="mr-3 mb-2"
				icon="ranking-1"
				:text="$t('gamification.to_see_ranking')"
			/>
			<ButtonPlay
				link="/gamification/config"
				icon="edit"
				:text="$t('gamification.to_edit_gamification')"
			/>
		</div>
	</div>
</template>

<script>
import TitlePage from "@/components/common/TitlePage.vue";

export default {
	components: {
		TitlePage,
	},
};
</script>

<style lang="scss" scoped>
.gamification {
	padding: 64px 40px;
	.info {
		color: var(--maincolor) !important;
	}
}

@media (max-width: 768px) {
	.gamification {
		padding: 32px 16px;
	}
}
</style>
