<template>
  <div class="gamification d-flex flex-column">
    <div class="gamification__about-section p-0 col-12 col-md-10">
      <TitlePage class="mb-2" :title="$t('gamification.title')">
        <IconSax name="cup" />
      </TitlePage>

      <div class="gamification__about-section__info">
        <TextPlay color="var(--text-color)" :text="$t('gamification.student_about')" />
      </div>
    </div>

    <DividerPlay class="my-4" />

    <div class="gamification__tabs p-0">
      <TabsPlay enableAllTabs :tabs="tabs" v-model="selectedTab">
        <template slot="0">
          <StudentRankingTab />
        </template>
        <template slot="1">
          <StudentMedalsTab />
        </template>
        <!-- <template slot="2"><StudentWalletTab /></template> -->
      </TabsPlay>
    </div>
  </div>
</template>

<script>
import TitlePage from "@/components/common/TitlePage.vue";
import StudentRankingTab from "@/components/StudentRankingTab.vue";
import StudentMedalsTab from "@/components/StudentMedalsTab.vue";
import fp from "lodash/fp"
// import StudentWalletTab from "@/components/StudentWalletTab.vue";

export default {
  components: {
    TitlePage,
    StudentRankingTab,
    StudentMedalsTab,
    // StudentWalletTab,
  },
  data() {
    return {
      tabs: [
        { id: 'ranking', icon: 'ranking-1', label: 'Ranking' },
        { id: 'medals', icon: 'buy-crypto', label: 'Medalhas' },
        // { icon: 'wallet', label: 'Carteira' },
      ],
      selectedTab: 0,
    };
  },
  mounted() {
    //TODO: move logic to TabsPlay
    const selected = fp.findIndex((it) => it.id == this.$route.query.tab, this.tabs);
    this.selectedTab = Math.max(selected, 0);
  }
};
</script>

<style lang="scss" scoped>
.gamification {
  padding: 64px 40px;
}

@media (max-width: 768px) {
  .gamification {
    padding: 32px 20px;
  }
}
</style>
